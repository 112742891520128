// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/william-jackdalessandro/Documents/Javascript-Stuff/my-website/bjdooi.github.io/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/Users/william-jackdalessandro/Documents/Javascript-Stuff/my-website/bjdooi.github.io/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/william-jackdalessandro/Documents/Javascript-Stuff/my-website/bjdooi.github.io/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/Users/william-jackdalessandro/Documents/Javascript-Stuff/my-website/bjdooi.github.io/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-invoice-js": () => import("/Users/william-jackdalessandro/Documents/Javascript-Stuff/my-website/bjdooi.github.io/src/pages/invoice.js" /* webpackChunkName: "component---src-pages-invoice-js" */),
  "component---src-pages-locksmith-js": () => import("/Users/william-jackdalessandro/Documents/Javascript-Stuff/my-website/bjdooi.github.io/src/pages/locksmith.js" /* webpackChunkName: "component---src-pages-locksmith-js" */),
  "component---src-pages-misc-js": () => import("/Users/william-jackdalessandro/Documents/Javascript-Stuff/my-website/bjdooi.github.io/src/pages/misc.js" /* webpackChunkName: "component---src-pages-misc-js" */),
  "component---src-pages-slides-js": () => import("/Users/william-jackdalessandro/Documents/Javascript-Stuff/my-website/bjdooi.github.io/src/pages/slides.js" /* webpackChunkName: "component---src-pages-slides-js" */),
  "component---src-pages-software-engineering-js": () => import("/Users/william-jackdalessandro/Documents/Javascript-Stuff/my-website/bjdooi.github.io/src/pages/software-engineering.js" /* webpackChunkName: "component---src-pages-software-engineering-js" */)
}

